// This is a skeleton starter React page generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react"
import { PlasmicFaq } from "../components/plasmic/stack_input_site/PlasmicFaq"
import Staff from "../components/Staff"
import {useState, useEffect} from "react"

function Faq() {
  // Use PlasmicFaq to render this component as it was
  // designed in Plasmic, by activating the appropriate variants,
  // attaching the appropriate event handlers, etc.  You
  // can also install whatever React hooks you need here to manage state or
  // fetch data.
  //
  // Props you can pass into PlasmicFaq are:
  // 1. Variants you want to activate,
  // 2. Contents for slots you want to fill,
  // 3. Overrides for any named node in the component to attach behavior and data,
  // 4. Props to set on the root node.
  //
  // By default, PlasmicFaq is wrapped by your project's global
  // variant context providers. These wrappers may be moved to
  // Gatsby "wrapRootElement" function
  // (https://www.gatsbyjs.com/docs/reference/config-files/gatsby-ssr#wrapRootElement).
  const [resources, setResources] = useState([]);

  useEffect(() => {
    var airtable_url = "https://api.airtable.com/v0/appldDJDdY8eUHXyx/Staff?api_key=" + process.env.GATSBY_AIRTABLE_KEY
    fetch(airtable_url)
      .then((res) => res.json())
      .then((resources) => {
        setResources(resources);
        console.log(resources);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);


  if (resources.records) {
    return <PlasmicFaq

      staffList={{
        children: resources.records.map((item) => {
          return (
            <Staff
              name={item.fields.Name}
              linkOut={item.fields.URL}
              photoIn={item.fields.photo[0].url}
            />
          )
        })
      }}
    />
  }
  return <PlasmicFaq />
}

export default Faq
