// extracted by mini-css-extract-plugin
export var column___7Kano = "PlasmicFaq-module--column___7Kano--7aOyu";
export var column__ii1DT = "PlasmicFaq-module--column__ii1DT--0739n";
export var column__uOg4 = "PlasmicFaq-module--column__uOg4--rNV3q";
export var column__wrTk = "PlasmicFaq-module--column__wrTk--u-YLo";
export var footer = "PlasmicFaq-module--footer--tIT0i";
export var freeBox___1K11H = "PlasmicFaq-module--freeBox___1K11H--mzltr";
export var freeBox___3PSbr = "PlasmicFaq-module--freeBox___3PSbr--tbgJg";
export var freeBox__gSAmc = "PlasmicFaq-module--freeBox__gSAmc--vvBcG";
export var freeBox__ggRfj = "PlasmicFaq-module--freeBox__ggRfj--8PrXL";
export var freeBox__lmiaJ = "PlasmicFaq-module--freeBox__lmiaJ--nJma0";
export var freeBox__teGq6 = "PlasmicFaq-module--freeBox__teGq6--XatHD";
export var freeBox__wIbXs = "PlasmicFaq-module--freeBox__wIbXs--LPzMV";
export var freeBox__xVqez = "PlasmicFaq-module--freeBox__xVqez---6W-O";
export var freeBox__yXO = "PlasmicFaq-module--freeBox__yXO--AcjyL";
export var freeBox__yvQwU = "PlasmicFaq-module--freeBox__yvQwU--Lm2aP";
export var h1__iXuQ = "PlasmicFaq-module--h1__iXuQ--ClA1T";
export var h1__jPci = "PlasmicFaq-module--h1__jPci--p5mDr";
export var h1__x5XnD = "PlasmicFaq-module--h1__x5XnD--4SMPM";
export var navBar = "PlasmicFaq-module--navBar--GCo1M";
export var ol = "PlasmicFaq-module--ol--1sR1n";
export var root = "PlasmicFaq-module--root--mikKs";
export var staff = "PlasmicFaq-module--staff--vXRqv";
export var staffList = "PlasmicFaq-module--staffList--dDA9Y";
export var text___5G7HO = "PlasmicFaq-module--text___5G7HO--NCnve";
export var text__bvNbC = "PlasmicFaq-module--text__bvNbC--BGaao";
export var text__dtjkF = "PlasmicFaq-module--text__dtjkF--58gLC";
export var text__iTkR7 = "PlasmicFaq-module--text__iTkR7--TaE8r";
export var text__js9Bm = "PlasmicFaq-module--text__js9Bm--0jnUd";
export var text__nTxCo = "PlasmicFaq-module--text__nTxCo--Jq0LP";
export var text__pIGw = "PlasmicFaq-module--text__pIGw--ISXjP";
export var text__s62G3 = "PlasmicFaq-module--text__s62G3--3E1vv";
export var text__v7CAz = "PlasmicFaq-module--text__v7CAz--aQL4x";
export var text__vaXuv = "PlasmicFaq-module--text__vaXuv--0TQfB";
export var text__vsZox = "PlasmicFaq-module--text__vsZox--TVdcO";