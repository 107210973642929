// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react"
import { classNames } from "@plasmicapp/react-web"

export function LinkSvgrepoComsvgIcon(props) {
  const { className, style, title, ...restProps } = props
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      version={"1.1"}
      x={"0"}
      y={"0"}
      viewBox={"0 0 195.085 195.085"}
      xmlSpace={"preserve"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",
        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M179.617 15.453l-.154-.149c-18.689-18.549-48.477-20.463-69.37-4.441-2.091 1.599-3.776 3.053-5.302 4.575a8.154 8.154 0 00-.13.133L71.224 49.012a7.498 7.498 0 00.001 10.606 7.497 7.497 0 0010.606-.001l33.561-33.566.104-.105c1.023-1.01 2.205-2.02 3.715-3.174 15.008-11.508 36.411-10.098 49.789 3.281l.134.131c14.652 14.786 14.611 38.742-.124 53.483l-33.559 33.563a7.498 7.498 0 005.304 12.802 7.478 7.478 0 005.304-2.197l33.56-33.563c20.622-20.631 20.622-54.195-.002-74.819zM113.23 135.437l-33.541 33.542a9.483 9.483 0 00-.196.205 37.648 37.648 0 01-12.945 8.333c-13.995 5.418-29.888 2.07-40.481-8.524-14.768-14.784-14.768-38.84 0-53.619L59.624 81.83a7.506 7.506 0 002.197-5.305v-.013a7.494 7.494 0 00-7.5-7.494 7.479 7.479 0 00-5.428 2.328l-33.435 33.422c-20.61 20.628-20.612 54.195-.002 74.828 10.095 10.097 23.628 15.479 37.411 15.479a52.87 52.87 0 0019.084-3.566 52.518 52.518 0 0018.326-11.896c.076-.075.15-.153.223-.232l33.337-33.337a7.501 7.501 0 00-10.607-10.607z"
        }
      ></path>

      <path
        d={
          "M59.15 135.908a7.476 7.476 0 005.304 2.197 7.477 7.477 0 005.303-2.196l66.164-66.161a7.498 7.498 0 00.001-10.606 7.5 7.5 0 00-10.606-.001l-66.164 66.161a7.497 7.497 0 00-.002 10.606z"
        }
      ></path>
    </svg>
  )
}

export default LinkSvgrepoComsvgIcon
/* prettier-ignore-end */
